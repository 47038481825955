import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/the-benefits-of-using-a-real-estate-agent-in-san-diego"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_The Benefits of Using a Real Estate Agent in San Diego.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                With its vibrant neighborhoods, stunning coastline, and booming real estate market,
                it's no wonder San Diego is a hot spot for homebuyers and sellers alike. Because it
                is such a coveted destination, navigating the buying or selling market can be
                daunting, with unprecedented competition. Working with a real estate agent becomes a
                must to ensure you have a smooth process, no matter your real estate needs.
              </CommonParagraph>
              <CommonParagraph>
                Here are five of the many benefits of using a realtor, whether selling or shopping
                for a home in San Diego.
              </CommonParagraph>
              <Heading type="h2">1. Local Knowledge</Heading>
              <CommonParagraph>
                One of the biggest perks of selling or{" "}
                <BlogLink
                  url="https://selbysellssd.com/buy-house-san-diego"
                  text="buying a house"
                />{" "}
                with a realtor is their local expertise. A real estate agent in San Diego will know
                market trends and pricing dynamics. That knowledge can help secure top dollar for
                your home or allow you to swoop in on a competitively priced one.
              </CommonParagraph>
              <CommonParagraph>
                An added benefit for those looking to buy is that they can help guide you to the
                neighborhood that best complements your lifestyle. From a laid-back beach community
                to a quiet suburb or prime downtown location, they make it much faster to find your
                dream place.
              </CommonParagraph>
              <Heading type="h2">2. Industry Connections</Heading>
              <CommonParagraph>
                There are so many steps involved when{" "}
                <BlogLink
                  url="https://selbysellssd.com/sell-your-san-diego-home"
                  text="selling a home"
                />
                . Could your home benefit from a fresh coat of paint? Do you have a professional
                cleaning service in mind? And when you do have an offer, how do you find a reputable
                inspector?
              </CommonParagraph>
              <CommonParagraph>
                These are all areas a local realtor may be able to help with! Typically, real estate
                agents have numerous connections within the real estate industry and related ones,
                such as cleaning companies.
              </CommonParagraph>
              <CommonParagraph>
                Those connections can benefit buyers, too! You may need a short-term rental while
                you house hunt or want to be one of the first to be notified as soon as an option
                opens up in a specific area. A real estate agent's connections can help with both.
              </CommonParagraph>
              <Heading type="h2">3. Access to Exclusive Listings</Heading>
              <CommonParagraph>
                While agents talk to one another, working to find suitable options for their
                clients, they don't need to rely on word of mouth. They also have access to
                exclusive listings, enabling them to connect with realtors with whom they may not
                usually cross paths. Did you know that many properties never have a public listing?
              </CommonParagraph>
              <CommonParagraph>
                There are multiple off-market opportunities that you can only explore with the help
                of a realtor. For sellers, this helps you target qualified buyers. For those looking
                for a home, this access gives you a competitive advantage, allowing you to make an
                offer before the public even knows the property is available.
              </CommonParagraph>
              <Heading type="h2">4. Streamlined Processes</Heading>
              <CommonParagraph>
                A streamlined process can benefit sellers and buyers, and an experienced agent will
                provide that. A clear path forward can also help you get to the finish line more
                quickly.
              </CommonParagraph>
              <CommonParagraph>
                A lot of paperwork is involved in real estate, but{" "}
                <BlogLink
                  url="https://selbysellssd.com/how-to-choose-a-real-estate-agent-in-san-diego/"
                  text="working with the right agent"
                />{" "}
                can make it seem like an easy, even enjoyable, process.
              </CommonParagraph>
              <Heading type="h2">5. Peace of Mind</Heading>
              <CommonParagraph>
                Perhaps the most underrated benefit of working with a realtor when involved in San
                Diego's real estate market is the peace of mind it brings.{" "}
              </CommonParagraph>
              <CommonParagraph>
                From an initial consultation through to closing, an agent offers guidance, support,
                and encouragement every step of the way. With a realtor on your team, you can feel
                confident throughout the process.
              </CommonParagraph>
              <Heading type="h2">Achieve Your Real Estate Goals With The Selby Team</Heading>
              <CommonParagraph>
                Whether buying, selling, or investing in San Diego's real estate market, The Selby
                Team offers the seamless, enjoyable experience you deserve.{" "}
                <ContactSlideoutLink text="Reach out" /> to get started today!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
